import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProductSearchState } from '../models/product-search-state';
import { Price } from '../models/price';
import { ProductVariant } from '../models/product-variant';
import { imgContext } from './results';
import { Product, ProductStoreHref } from '../models/product';
import { Region } from '../models/region';
import messages from './messages';
import { defaultLocale } from '../App';

import './results-variants.scss';

const ResultsProductVariants: React.FC<ProductSearchState> = (props) => {
    const intl = useIntl();

    const onVisitStore = (variant: ProductVariant) => {
        let href = '';
        if (variant.href) {
            if (variant.href.hasOwnProperty(defaultLocale)) {
                const storeHref = variant.href as ProductStoreHref;
                href = storeHref[`${props.locale}-${props.region?.abbreviation}`] || storeHref[props.locale] || storeHref[defaultLocale];
            } else {
                href = variant.href as string;
            }
        } else {
            if (props.productForResult) {
                if (props.productForResult.href.hasOwnProperty(defaultLocale)) {
                    const storeHref = props.productForResult.href as ProductStoreHref;
                    href = storeHref[`${props.locale}-${props.region?.abbreviation}`] || storeHref[props.locale] || storeHref[defaultLocale];
                } else {
                    href = props.productForResult.href as string;
                }
            }
        }
        window.location.href = href;
    }

    const getVariantPrice = (region: Region, variant: ProductVariant) => {
        let price = variant.pricing.find(f => f.regionCode.toUpperCase() === region.abbreviation.toUpperCase())
        return price as Price;
    }

    const getShipment = (region: Region, variant: ProductVariant) => {
        const price = getVariantPrice(region, variant);
        if (variant.shipping === "free") {
            return (
                <>
                    <div className="result-variant--shipping1">
                        <FormattedMessage id="results-variants.free-shipping" defaultMessage="FREE"></FormattedMessage>
                    </div>
                    <div className="result-variant--shipping2">
                        <FormattedMessage id="results-variants.shipping-to" defaultMessage="Shipping to {region}"
                            values={{
                                region: price.regionCode
                            }}
                        />
                    </div>
                </>
            )
        }
        if (variant.shipping === "free USA shipping" && price.regionCode === "US") {
            return (
                <div className="result-variant--shipping1">
                    <FormattedMessage id="results-variants.free-usa-shipping" defaultMessage="FREE USA SHIPPING"></FormattedMessage>
                </div>
            )
        }

        return (
            <div className="result-variant--shipping2">
                <FormattedMessage id="results-variants.paid-shipping" defaultMessage="Paid shipping to {region}"
                    values={{
                        region: price.regionCode
                    }}
                />
            </div>
        );
    }

    const getVariants = (region: Region, variant: ProductVariant, index: number) => {
        const price = getVariantPrice(region, variant);
        return (
            <div className="container mb-3" key={variant.name + '_' + index}>
                <div className="row result-variant--row">
                    <div className="col-6 col-md-3 mb-3 mb-md-0">
                        <div className="result-variant--row-cell">
                            <div className="result-variant--name">{variant.name}</div>
                            <div className="result-variant--description">{variant.description}</div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 mb-3 mb-md-0 order-md-6">
                        <div className="result-variant--row-cell">
                            <button className="btn result-variant--shop-btn" onClick={() => onVisitStore(variant)}>{intl.formatMessage(messages.visitStore)}</button>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 ">
                        <div className="result-variant--row-cell">
                            <img className="result-variant-prod-img" src={imgContext('./' + variant.image)} alt={variant.image}></img>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="result-variant--tag result-variant--row-cell">
                            <div className="result-variant--tag-prev">
                                <FormattedMessage id="results-variants.originally.v2" defaultMessage="Originally:" />
                            </div>
                            <div className="result-variant--tag-prev result-variant--tag-prev__strike">{price.previous}</div>
                            <div className="result-variant--tag-current">{price.current}</div>
                            <div className="result-variant--tag-units">{variant.units}</div>
                        </div>
                    </div>
                    <div className="col-6 col-md mt-3 mt-mb-0">
                        <div className="result-variant--row-cell">
                            <div className="result-variant--discount">
                                <FormattedMessage id="results-variants.save-discount" defaultMessage="Save {discount}%"
                                    values={{
                                        discount: (<span>{price.discount}</span>)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md mt-3 mt-md-0">
                        <div className="result-variant--row-cell">
                            {getShipment(region, variant)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const product = props.productForResult as Product;
    const region = props.regionForResult as Region;
    if (!product || !region) {
        return (<></>);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5 className="result-variant--caption">
                        <FormattedMessage id="results-variants.prices-and-savings" defaultMessage="Prices and Savings"></FormattedMessage>
                    </h5>
                    {product.variants.map((price, index) => getVariants(region, price, index))}
                </div>
            </div>
        </div>
    );
};

export default ResultsProductVariants;