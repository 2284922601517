import React from 'react';
import '../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
import logo from '../img/logo.png';
import { ProductSearchState } from '../models/product-search-state';
import HeaderProductSearch from './header-product-search';
import './header.scss';



const Header: React.FC<ProductSearchState> = (props) => {

    return (
        <header>
            <div className="store-header--logo">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <img className="image-fluid store-header--logo-img" src={logo} alt="store finder logo" onClick={props.onGoBack}></img>
                        </div>
                    </div>
                </div>
            </div>
            <HeaderProductSearch {...props}></HeaderProductSearch>
        </header>
    );
}

export default Header;
