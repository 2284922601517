import marked from 'marked';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProductSearchState } from '../models/product-search-state';
import ModalDialog from './modal';

const TermsAndConditions: React.FC<ProductSearchState> = (props) => {
    let mounted = false;
    const [markdown, setMarkdown] = useState('');

    const translationUrl = `${process.env.PUBLIC_URL}/terms-and-conditions/terms-and-conditions.${props.locale}.md`;
    const defaultUrl = `${process.env.PUBLIC_URL}/terms-and-conditions/terms-and-conditions.en.md`;

    useEffect(() => {
        mounted = true;
        fetch(translationUrl)
            .then(response => {
                if (response.status === 404) {
                    fetch(defaultUrl).then(response => response.text())
                        .then(text => {
                            if (mounted) {
                                setMarkdown(marked(text));
                            }
                        })
                } else {
                    response.text().then(text => {
                        if (mounted) {
                            setMarkdown(marked(text));
                        }
                    });
                }
            });
        return () => {
            mounted = false;
        }
    }, []);

    return (
        <ModalDialog linkName={<FormattedMessage id="footer.terms-and-comditions" defaultMessage="Terms & Conditions"></FormattedMessage>}>
            <div className="footer--modal-contents">
                <div dangerouslySetInnerHTML={{ __html: markdown }}>
                </div>
            </div>
        </ModalDialog>
    );
}
export default TermsAndConditions;
