import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Product } from './models/product';



function getFormattedMessage(obj: any) {
    if (obj.hasOwnProperty('messageId')) {
        return (<FormattedMessage id={obj.messageId} />);
    }
    return obj;
}

export function LocalizeProduct(product: Product){
    product.name = getFormattedMessage(product.name);
    product.variants.forEach(variant => {
        variant.name = getFormattedMessage(variant.name);
        variant.units = getFormattedMessage(variant.units);
        variant.description = getFormattedMessage(variant.description);
    });

    product.comments.forEach(comment => {
        comment.message = getFormattedMessage(comment.message);
    });

    return product;
}