import { defineMessages } from 'react-intl';

const messages = defineMessages({
    enterYourName: {
        id: 'placeholder.enter-name',
        defaultMessage: 'Please enter your name...'
    },
    tellUs: {
        id: 'placeholder.tell-us-what-you-think',
        defaultMessage: 'Tell us what you think...'
    },
    enterProductName: {
        id: 'placeholder.enter-product-name',
        defaultMessage: 'Enter product name...'
    },
    enterCountryName: {
        id: 'placeholder.enter-country-name',
        defaultMessage: 'Choose your country'
    },
    btnSearch: {
        id: 'button.search',
        defaultMessage: 'Search'
    },
    safeAndSecure: {
        id: 'safeAndSecure',
        defaultMessage: 'Safe and Secure'
    },
    visitStore: {
        id: 'button.visit-store',
        defaultMessage: 'Visit Store'
    },
    visitorRating: {
        id: 'store-info.visitor-rating',
        defaultMessage: 'Visitor Rating'
    },
    reviews: {
        id: 'store-info.reviews',
        defaultMessage: 'Reviews'
    },
    verification: {
        id: 'store-info.verification',
        defaultMessage: 'Verification'
    },
    trustScore: {
        id: 'store-info.trust-score',
        defaultMessage: 'Trust Score'
    },
});

export default messages;