import React from 'react';
import { ProductSearchState } from '../models/product-search-state';
import StarRatings from './star-rating';
import { imgContext } from './results';
import { FormattedMessage, useIntl } from 'react-intl';
import { Product, ProductStoreHref } from '../models/product';
import messages from './messages';
import { defaultLocale } from '../App';

import './results-store-info.scss';
import verified from './../img/check.svg';

const ResultsStoreInfo: React.FC<ProductSearchState> = (props) => {
    const intl = useIntl();

    const onVisitStore = () => {
        let href = '';

        if (props.productForResult) {
            if (props.productForResult.href.hasOwnProperty(defaultLocale)) {
                const storeHref = props.productForResult.href as ProductStoreHref;
                href = storeHref[`${props.locale}-${props.region?.abbreviation}`] || storeHref[props.locale] || storeHref[defaultLocale];
            } else {
                href = props.productForResult.href as string;
            }
        }

        window.location.href = href;
    }

    const getVerification = (isVerified: boolean) => {
        return isVerified ? (<img className="store-info-verified" src={verified}></img>) : "";
    }

    const product = props.productForResult as Product;
    if (!product) {
        return (<></>);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5 className="store-info--caption">
                        <FormattedMessage id="results-store-info.official-store-information" defaultMessage="Official Store Information"></FormattedMessage>
                    </h5>
                </div>
            </div>

            <div className="store-info--canvas ">
                {/* Mobile View */}
                <div className="row store-info--mobile">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <img className="store-info--logo" src={imgContext('./' + product.logo)} alt={product.name}></img>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-primary store-info--row-cell store-info--shop-btn" onClick={onVisitStore}>
                                    <FormattedMessage id="results-store-info.visit-store" defaultMessage="Visit Store"></FormattedMessage>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="store-info--mobile-metrics">
                        <div className="">
                            <div className="store-info--mobile-metrics-label">
                                {intl.formatMessage(messages.trustScore)}
                            </div>
                            <div className="store-info--mobile-metrics-value">
                                <div className="store-info--row-cell store-info--trust-score">{product.trustScore}/100</div>
                            </div>
                        </div>
                        <div className="">
                            <div className="store-info--mobile-metrics-label">
                                {intl.formatMessage(messages.visitorRating)}
                            </div>
                            <div className="store-info--mobile-metrics-value">
                                <div className="store-info--row-cell">
                                    <StarRatings
                                        rating={product.rating}
                                        starDimension="1.2rem"
                                        starSpacing="0"
                                        starRatedColor="#faca12"
                                        starEmptyColor="#c2c2c2"
                                        starHoverColor="#faca12"
                                        numberOfStars={5}
                                        isAggregateRating={true}
                                        name='rating'
                                    />
                                    <div className="store-info--rating-count">({product.userRatingCount})</div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="store-info--mobile-metrics-label">
                                {intl.formatMessage(messages.reviews)}
                            </div>
                            <div className="store-info--mobile-metrics-value">
                                <div className="store-info--row-cell ">
                                    <span className="store-info--reviews">{product.comments.length}</span>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="store-info--mobile-metrics-label">
                                {intl.formatMessage(messages.verification)}
                            </div>
                            <div className="store-info--mobile-metrics-value">
                                <div className="store-info--row-cell">
                                    {getVerification(product.verified)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Desktop view */}
                <div className="d-none d-md-block">
                    <div className="store-info--desktop">
                        <div className="row store-info--header ">
                            <div className="col-3">
                                {/* empty for logo */}
                            </div>
                            <div className="col">
                                {intl.formatMessage(messages.trustScore)}
                            </div>
                            <div className="col">
                                {intl.formatMessage(messages.visitorRating)}
                            </div>
                            <div className="col">
                                {intl.formatMessage(messages.reviews)}
                            </div>
                            <div className="col">
                                {intl.formatMessage(messages.verification)}
                            </div>
                            <div className="col-2">
                                {/* empty for button */}
                            </div>
                        </div>
                        <div className="row store-info--row align-content-center justify-content-center ">
                            <div className="col-3 ">
                                <img className="store-info--logo" src={imgContext('./' + product.logo)} alt={product.name}></img>
                            </div>
                            <div className="col">
                                <div className="store-info--row-cell store-info--trust-score">{product.trustScore}/100</div>
                            </div>
                            <div className="col-auto">
                                <div className="store-info--row-cell">
                                    <StarRatings
                                        rating={product.rating}
                                        starDimension="1.4em"
                                        starSpacing="0.05em"
                                        starRatedColor="#faca12"
                                        starEmptyColor="#c2c2c2"
                                        starHoverColor="#faca12"
                                        numberOfStars={5}
                                        isAggregateRating={true}
                                        name='rating'
                                    />
                                    <div className="store-info--rating-count">({product.userRatingCount})</div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="store-info--row-cell">
                                    <span className="store-info--reviews">{product.comments.length}</span>
                                </div>
                            </div>
                            <div className="col">
                                <div className="store-info--row-cell">
                                    {getVerification(product.verified)}
                                </div>
                            </div>
                            <div className="col-2">
                                <button className="btn btn-primary store-info--row-cell store-info--shop-btn" onClick={onVisitStore}>{intl.formatMessage(messages.visitStore)}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResultsStoreInfo;
