import React, { ChangeEvent, useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FormattedMessage, useIntl } from 'react-intl';
import '../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
import lock from '../img/lock.png';
import { ProductSearchState } from '../models/product-search-state';
import { Region } from '../models/region';
import messages from './messages';
import './product-search.scss';
import verified from './../img/check.svg';


const ProductSearch: React.FC<ProductSearchState> = (props) => {

    const intl = useIntl();

    const [productName, setProductName] = useState('');
    const [productMatch, setProductMatch] = useState(false);

    useEffect(() => {
        if (props.product && !productMatch) {
            setProductName(props.product.name);
        }
    });

    const onProductSearched = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            const searchToken = e.target.value.toLowerCase().trim();
            const product = props.products.find(f => {
                return f.name === searchToken || f.keywords.find(ff => ff.toLowerCase() === searchToken);
            });
            if (product) {
                props.onProductSelected(product);
                setProductMatch(true);
            } else {
                props.onProductSelected(undefined);
                setProductMatch(false);
            }
        } else {
            props.onProductSelected(undefined);
            setProductMatch(false);
        }
        setProductName(e.target.value);
    }

    const onRegionSelected = (region: Region[]) => {
        if (region && region.length) {
            props.onRegionSelected(region[0]);
        } else {
            props.onRegionSelected(undefined);
        };
    }
    const showVerified = () => {
        return productMatch ? (<img className="product-search--verified" src={verified}></img>) : '';
    }

    return (
        <div className="product-search">
            <div className="product-search--hint">
                <FormattedMessage id="product-search.hint" defaultMessage="Start searching - locate official online store of the product in interest"></FormattedMessage>
            </div>
            <div className="product-search--search">
                <div className="rbt">
                    <input
                        name="product-search"
                        className="rbt-input-main form-control rbt-input  input-lg form-control-lg"
                        placeholder={intl.formatMessage(messages.enterProductName)}
                        onChange={onProductSearched}
                        value={productName}>
                    </input>
                    {showVerified()}
                </div>
                <Typeahead
                    id="region-select"
                    inputProps={{ name: 'region-search', autoComplete: 'off' }}
                    labelKey={r => r.country}
                    multiple={false}
                    maxResults={50}
                    bsSize={'large'}
                    clearButton={true}
                    selected={props.region ? [props.region] : []}
                    options={props.regions}
                    placeholder={intl.formatMessage(messages.enterCountryName)}
                    selectHintOnEnter={true}
                    flip={true}
                    onChange={onRegionSelected}
                    ignoreDiacritics={true}
                    caseSensitive={false}
                />
                <div className="product-search--button-container">
                    <div className="product-search--secure d-md-none" >
                        <img src={lock} alt="Secure search"></img>
                        {intl.formatMessage(messages.safeAndSecure)}
                    </div>
                    <button className="btn btn-primary product-search--button" type="button" onClick={props.onSearch}>{intl.formatMessage(messages.btnSearch)}</button>
                </div>
            </div>
            <div className="product-search--secure mb-3 d-none d-md-block">
                <img src={lock} alt="Secure search"></img>
                {intl.formatMessage(messages.safeAndSecure)}
            </div>
        </div>
    );
}

export default ProductSearch;
