import { defineMessages, MessageDescriptor } from 'react-intl';

// data taken from https://github.com/umpirsky/country-list

const regionMessages = defineMessages<MessageDescriptor, Record<string, MessageDescriptor>>({
    "regionAF": {
        "id": "region.AF",
        "defaultMessage": "Afghanistan"
    },
    "regionAX": {
        "id": "region.AX",
        "defaultMessage": "Åland Islands"
    },
    "regionAL": {
        "id": "region.AL",
        "defaultMessage": "Albania"
    },
    "regionDZ": {
        "id": "region.DZ",
        "defaultMessage": "Algeria"
    },
    "regionAS": {
        "id": "region.AS",
        "defaultMessage": "American Samoa"
    },
    "regionAD": {
        "id": "region.AD",
        "defaultMessage": "Andorra"
    },
    "regionAO": {
        "id": "region.AO",
        "defaultMessage": "Angola"
    },
    "regionAI": {
        "id": "region.AI",
        "defaultMessage": "Anguilla"
    },
    "regionAQ": {
        "id": "region.AQ",
        "defaultMessage": "Antarctica"
    },
    "regionAG": {
        "id": "region.AG",
        "defaultMessage": "Antigua & Barbuda"
    },
    "regionAR": {
        "id": "region.AR",
        "defaultMessage": "Argentina"
    },
    "regionAM": {
        "id": "region.AM",
        "defaultMessage": "Armenia"
    },
    "regionAW": {
        "id": "region.AW",
        "defaultMessage": "Aruba"
    },
    "regionAC": {
        "id": "region.AC",
        "defaultMessage": "Ascension Island"
    },
    "regionAU": {
        "id": "region.AU",
        "defaultMessage": "Australia"
    },
    "regionAT": {
        "id": "region.AT",
        "defaultMessage": "Austria"
    },
    "regionAZ": {
        "id": "region.AZ",
        "defaultMessage": "Azerbaijan"
    },
    "regionBS": {
        "id": "region.BS",
        "defaultMessage": "Bahamas"
    },
    "regionBH": {
        "id": "region.BH",
        "defaultMessage": "Bahrain"
    },
    "regionBD": {
        "id": "region.BD",
        "defaultMessage": "Bangladesh"
    },
    "regionBB": {
        "id": "region.BB",
        "defaultMessage": "Barbados"
    },
    "regionBY": {
        "id": "region.BY",
        "defaultMessage": "Belarus"
    },
    "regionBE": {
        "id": "region.BE",
        "defaultMessage": "Belgium"
    },
    "regionBZ": {
        "id": "region.BZ",
        "defaultMessage": "Belize"
    },
    "regionBJ": {
        "id": "region.BJ",
        "defaultMessage": "Benin"
    },
    "regionBM": {
        "id": "region.BM",
        "defaultMessage": "Bermuda"
    },
    "regionBT": {
        "id": "region.BT",
        "defaultMessage": "Bhutan"
    },
    "regionBO": {
        "id": "region.BO",
        "defaultMessage": "Bolivia"
    },
    "regionBA": {
        "id": "region.BA",
        "defaultMessage": "Bosnia & Herzegovina"
    },
    "regionBW": {
        "id": "region.BW",
        "defaultMessage": "Botswana"
    },
    "regionBR": {
        "id": "region.BR",
        "defaultMessage": "Brazil"
    },
    "regionBV": {
        "id": "region.BV",
        "defaultMessage": "Bouvet Island"
    },
    "regionTP": {
        "id": "region.TP",
        "defaultMessage": "East Timor"
    },
    "regionHM": {
        "id": "region.HM",
        "defaultMessage": "Heard Island and McDonald Islands"
    },
    "regionAN": {
        "id": "region.AN",
        "defaultMessage": "Netherlands Antilles"
    },
    "regionYU": {
        "id": "region.YU",
        "defaultMessage": "Yugoslavia"
    },
    "regionIO": {
        "id": "region.IO",
        "defaultMessage": "British Indian Ocean Territory"
    },
    "regionVG": {
        "id": "region.VG",
        "defaultMessage": "British Virgin Islands"
    },
    "regionBN": {
        "id": "region.BN",
        "defaultMessage": "Brunei"
    },
    "regionBG": {
        "id": "region.BG",
        "defaultMessage": "Bulgaria"
    },
    "regionBF": {
        "id": "region.BF",
        "defaultMessage": "Burkina Faso"
    },
    "regionBI": {
        "id": "region.BI",
        "defaultMessage": "Burundi"
    },
    "regionKH": {
        "id": "region.KH",
        "defaultMessage": "Cambodia"
    },
    "regionCM": {
        "id": "region.CM",
        "defaultMessage": "Cameroon"
    },
    "regionCA": {
        "id": "region.CA",
        "defaultMessage": "Canada"
    },
    "regionIC": {
        "id": "region.IC",
        "defaultMessage": "Canary Islands"
    },
    "regionCV": {
        "id": "region.CV",
        "defaultMessage": "Cape Verde"
    },
    "regionBQ": {
        "id": "region.BQ",
        "defaultMessage": "Caribbean Netherlands"
    },
    "regionKY": {
        "id": "region.KY",
        "defaultMessage": "Cayman Islands"
    },
    "regionCF": {
        "id": "region.CF",
        "defaultMessage": "Central African Republic"
    },
    "regionEA": {
        "id": "region.EA",
        "defaultMessage": "Ceuta & Melilla"
    },
    "regionTD": {
        "id": "region.TD",
        "defaultMessage": "Chad"
    },
    "regionCL": {
        "id": "region.CL",
        "defaultMessage": "Chile"
    },
    "regionCN": {
        "id": "region.CN",
        "defaultMessage": "China"
    },
    "regionCX": {
        "id": "region.CX",
        "defaultMessage": "Christmas Island"
    },
    "regionCC": {
        "id": "region.CC",
        "defaultMessage": "Cocos (Keeling) Islands"
    },
    "regionCO": {
        "id": "region.CO",
        "defaultMessage": "Colombia"
    },
    "regionKM": {
        "id": "region.KM",
        "defaultMessage": "Comoros"
    },
    "regionCG": {
        "id": "region.CG",
        "defaultMessage": "Congo - Brazzaville"
    },
    "regionCD": {
        "id": "region.CD",
        "defaultMessage": "Congo - Kinshasa"
    },
    "regionCK": {
        "id": "region.CK",
        "defaultMessage": "Cook Islands"
    },
    "regionCR": {
        "id": "region.CR",
        "defaultMessage": "Costa Rica"
    },
    "regionCI": {
        "id": "region.CI",
        "defaultMessage": "Côte d’Ivoire"
    },
    "regionHR": {
        "id": "region.HR",
        "defaultMessage": "Croatia"
    },
    "regionCU": {
        "id": "region.CU",
        "defaultMessage": "Cuba"
    },
    "regionCW": {
        "id": "region.CW",
        "defaultMessage": "Curaçao"
    },
    "regionCY": {
        "id": "region.CY",
        "defaultMessage": "Cyprus"
    },
    "regionCZ": {
        "id": "region.CZ",
        "defaultMessage": "Czechia"
    },
    "regionDK": {
        "id": "region.DK",
        "defaultMessage": "Denmark"
    },
    "regionDG": {
        "id": "region.DG",
        "defaultMessage": "Diego Garcia"
    },
    "regionDJ": {
        "id": "region.DJ",
        "defaultMessage": "Djibouti"
    },
    "regionDM": {
        "id": "region.DM",
        "defaultMessage": "Dominica"
    },
    "regionDO": {
        "id": "region.DO",
        "defaultMessage": "Dominican Republic"
    },
    "regionEC": {
        "id": "region.EC",
        "defaultMessage": "Ecuador"
    },
    "regionEG": {
        "id": "region.EG",
        "defaultMessage": "Egypt"
    },
    "regionSV": {
        "id": "region.SV",
        "defaultMessage": "El Salvador"
    },
    "regionGQ": {
        "id": "region.GQ",
        "defaultMessage": "Equatorial Guinea"
    },
    "regionER": {
        "id": "region.ER",
        "defaultMessage": "Eritrea"
    },
    "regionEE": {
        "id": "region.EE",
        "defaultMessage": "Estonia"
    },
    "regionSZ": {
        "id": "region.SZ",
        "defaultMessage": "Eswatini"
    },
    "regionET": {
        "id": "region.ET",
        "defaultMessage": "Ethiopia"
    },
    "regionFK": {
        "id": "region.FK",
        "defaultMessage": "Falkland Islands"
    },
    "regionFO": {
        "id": "region.FO",
        "defaultMessage": "Faroe Islands"
    },
    "regionFJ": {
        "id": "region.FJ",
        "defaultMessage": "Fiji"
    },
    "regionFI": {
        "id": "region.FI",
        "defaultMessage": "Finland"
    },
    "regionFR": {
        "id": "region.FR",
        "defaultMessage": "France"
    },
    "regionGF": {
        "id": "region.GF",
        "defaultMessage": "French Guiana"
    },
    "regionPF": {
        "id": "region.PF",
        "defaultMessage": "French Polynesia"
    },
    "regionTF": {
        "id": "region.TF",
        "defaultMessage": "French Southern Territories"
    },
    "regionGA": {
        "id": "region.GA",
        "defaultMessage": "Gabon"
    },
    "regionGM": {
        "id": "region.GM",
        "defaultMessage": "Gambia"
    },
    "regionGE": {
        "id": "region.GE",
        "defaultMessage": "Georgia"
    },
    "regionDE": {
        "id": "region.DE",
        "defaultMessage": "Germany"
    },
    "regionGH": {
        "id": "region.GH",
        "defaultMessage": "Ghana"
    },
    "regionGI": {
        "id": "region.GI",
        "defaultMessage": "Gibraltar"
    },
    "regionGR": {
        "id": "region.GR",
        "defaultMessage": "Greece"
    },
    "regionGL": {
        "id": "region.GL",
        "defaultMessage": "Greenland"
    },
    "regionGD": {
        "id": "region.GD",
        "defaultMessage": "Grenada"
    },
    "regionGP": {
        "id": "region.GP",
        "defaultMessage": "Guadeloupe"
    },
    "regionGU": {
        "id": "region.GU",
        "defaultMessage": "Guam"
    },
    "regionGT": {
        "id": "region.GT",
        "defaultMessage": "Guatemala"
    },
    "regionGG": {
        "id": "region.GG",
        "defaultMessage": "Guernsey"
    },
    "regionGN": {
        "id": "region.GN",
        "defaultMessage": "Guinea"
    },
    "regionGW": {
        "id": "region.GW",
        "defaultMessage": "Guinea-Bissau"
    },
    "regionGY": {
        "id": "region.GY",
        "defaultMessage": "Guyana"
    },
    "regionHT": {
        "id": "region.HT",
        "defaultMessage": "Haiti"
    },
    "regionHN": {
        "id": "region.HN",
        "defaultMessage": "Honduras"
    },
    "regionHK": {
        "id": "region.HK",
        "defaultMessage": "Hong Kong SAR China"
    },
    "regionHU": {
        "id": "region.HU",
        "defaultMessage": "Hungary"
    },
    "regionIS": {
        "id": "region.IS",
        "defaultMessage": "Iceland"
    },
    "regionIN": {
        "id": "region.IN",
        "defaultMessage": "India"
    },
    "regionID": {
        "id": "region.ID",
        "defaultMessage": "Indonesia"
    },
    "regionIR": {
        "id": "region.IR",
        "defaultMessage": "Iran"
    },
    "regionIQ": {
        "id": "region.IQ",
        "defaultMessage": "Iraq"
    },
    "regionIE": {
        "id": "region.IE",
        "defaultMessage": "Ireland"
    },
    "regionIM": {
        "id": "region.IM",
        "defaultMessage": "Isle of Man"
    },
    "regionIL": {
        "id": "region.IL",
        "defaultMessage": "Israel"
    },
    "regionIT": {
        "id": "region.IT",
        "defaultMessage": "Italy"
    },
    "regionJM": {
        "id": "region.JM",
        "defaultMessage": "Jamaica"
    },
    "regionJP": {
        "id": "region.JP",
        "defaultMessage": "Japan"
    },
    "regionJE": {
        "id": "region.JE",
        "defaultMessage": "Jersey"
    },
    "regionJO": {
        "id": "region.JO",
        "defaultMessage": "Jordan"
    },
    "regionKZ": {
        "id": "region.KZ",
        "defaultMessage": "Kazakhstan"
    },
    "regionKE": {
        "id": "region.KE",
        "defaultMessage": "Kenya"
    },
    "regionKI": {
        "id": "region.KI",
        "defaultMessage": "Kiribati"
    },
    "regionXK": {
        "id": "region.XK",
        "defaultMessage": "Kosovo"
    },
    "regionKW": {
        "id": "region.KW",
        "defaultMessage": "Kuwait"
    },
    "regionKG": {
        "id": "region.KG",
        "defaultMessage": "Kyrgyzstan"
    },
    "regionLA": {
        "id": "region.LA",
        "defaultMessage": "Laos"
    },
    "regionLV": {
        "id": "region.LV",
        "defaultMessage": "Latvia"
    },
    "regionLB": {
        "id": "region.LB",
        "defaultMessage": "Lebanon"
    },
    "regionLS": {
        "id": "region.LS",
        "defaultMessage": "Lesotho"
    },
    "regionLR": {
        "id": "region.LR",
        "defaultMessage": "Liberia"
    },
    "regionLY": {
        "id": "region.LY",
        "defaultMessage": "Libya"
    },
    "regionLI": {
        "id": "region.LI",
        "defaultMessage": "Liechtenstein"
    },
    "regionLT": {
        "id": "region.LT",
        "defaultMessage": "Lithuania"
    },
    "regionLU": {
        "id": "region.LU",
        "defaultMessage": "Luxembourg"
    },
    "regionMO": {
        "id": "region.MO",
        "defaultMessage": "Macao SAR China"
    },
    "regionMG": {
        "id": "region.MG",
        "defaultMessage": "Madagascar"
    },
    "regionMW": {
        "id": "region.MW",
        "defaultMessage": "Malawi"
    },
    "regionMY": {
        "id": "region.MY",
        "defaultMessage": "Malaysia"
    },
    "regionMV": {
        "id": "region.MV",
        "defaultMessage": "Maldives"
    },
    "regionML": {
        "id": "region.ML",
        "defaultMessage": "Mali"
    },
    "regionMT": {
        "id": "region.MT",
        "defaultMessage": "Malta"
    },
    "regionMH": {
        "id": "region.MH",
        "defaultMessage": "Marshall Islands"
    },
    "regionMQ": {
        "id": "region.MQ",
        "defaultMessage": "Martinique"
    },
    "regionMR": {
        "id": "region.MR",
        "defaultMessage": "Mauritania"
    },
    "regionMU": {
        "id": "region.MU",
        "defaultMessage": "Mauritius"
    },
    "regionYT": {
        "id": "region.YT",
        "defaultMessage": "Mayotte"
    },
    "regionMX": {
        "id": "region.MX",
        "defaultMessage": "Mexico"
    },
    "regionFM": {
        "id": "region.FM",
        "defaultMessage": "Micronesia"
    },
    "regionMD": {
        "id": "region.MD",
        "defaultMessage": "Moldova"
    },
    "regionMC": {
        "id": "region.MC",
        "defaultMessage": "Monaco"
    },
    "regionMN": {
        "id": "region.MN",
        "defaultMessage": "Mongolia"
    },
    "regionME": {
        "id": "region.ME",
        "defaultMessage": "Montenegro"
    },
    "regionMS": {
        "id": "region.MS",
        "defaultMessage": "Montserrat"
    },
    "regionMA": {
        "id": "region.MA",
        "defaultMessage": "Morocco"
    },
    "regionMZ": {
        "id": "region.MZ",
        "defaultMessage": "Mozambique"
    },
    "regionMM": {
        "id": "region.MM",
        "defaultMessage": "Myanmar (Burma)"
    },
    "regionNA": {
        "id": "region.NA",
        "defaultMessage": "Namibia"
    },
    "regionNR": {
        "id": "region.NR",
        "defaultMessage": "Nauru"
    },
    "regionNP": {
        "id": "region.NP",
        "defaultMessage": "Nepal"
    },
    "regionNL": {
        "id": "region.NL",
        "defaultMessage": "Netherlands"
    },
    "regionNC": {
        "id": "region.NC",
        "defaultMessage": "New Caledonia"
    },
    "regionNZ": {
        "id": "region.NZ",
        "defaultMessage": "New Zealand"
    },
    "regionNI": {
        "id": "region.NI",
        "defaultMessage": "Nicaragua"
    },
    "regionNE": {
        "id": "region.NE",
        "defaultMessage": "Niger"
    },
    "regionNG": {
        "id": "region.NG",
        "defaultMessage": "Nigeria"
    },
    "regionNU": {
        "id": "region.NU",
        "defaultMessage": "Niue"
    },
    "regionNF": {
        "id": "region.NF",
        "defaultMessage": "Norfolk Island"
    },
    "regionKP": {
        "id": "region.KP",
        "defaultMessage": "North Korea"
    },
    "regionMK": {
        "id": "region.MK",
        "defaultMessage": "North Macedonia"
    },
    "regionMP": {
        "id": "region.MP",
        "defaultMessage": "Northern Mariana Islands"
    },
    "regionNO": {
        "id": "region.NO",
        "defaultMessage": "Norway"
    },
    "regionOM": {
        "id": "region.OM",
        "defaultMessage": "Oman"
    },
    "regionPK": {
        "id": "region.PK",
        "defaultMessage": "Pakistan"
    },
    "regionPW": {
        "id": "region.PW",
        "defaultMessage": "Palau"
    },
    "regionPS": {
        "id": "region.PS",
        "defaultMessage": "Palestinian Territories"
    },
    "regionPA": {
        "id": "region.PA",
        "defaultMessage": "Panama"
    },
    "regionPG": {
        "id": "region.PG",
        "defaultMessage": "Papua New Guinea"
    },
    "regionPY": {
        "id": "region.PY",
        "defaultMessage": "Paraguay"
    },
    "regionPE": {
        "id": "region.PE",
        "defaultMessage": "Peru"
    },
    "regionPH": {
        "id": "region.PH",
        "defaultMessage": "Philippines"
    },
    "regionPN": {
        "id": "region.PN",
        "defaultMessage": "Pitcairn Islands"
    },
    "regionPL": {
        "id": "region.PL",
        "defaultMessage": "Poland"
    },
    "regionPT": {
        "id": "region.PT",
        "defaultMessage": "Portugal"
    },
    "regionXA": {
        "id": "region.XA",
        "defaultMessage": "Pseudo-Accents"
    },
    "regionXB": {
        "id": "region.XB",
        "defaultMessage": "Pseudo-Bidi"
    },
    "regionPR": {
        "id": "region.PR",
        "defaultMessage": "Puerto Rico"
    },
    "regionQA": {
        "id": "region.QA",
        "defaultMessage": "Qatar"
    },
    "regionRE": {
        "id": "region.RE",
        "defaultMessage": "Réunion"
    },
    "regionRO": {
        "id": "region.RO",
        "defaultMessage": "Romania"
    },
    "regionRU": {
        "id": "region.RU",
        "defaultMessage": "Russia"
    },
    "regionRW": {
        "id": "region.RW",
        "defaultMessage": "Rwanda"
    },
    "regionWS": {
        "id": "region.WS",
        "defaultMessage": "Samoa"
    },
    "regionSM": {
        "id": "region.SM",
        "defaultMessage": "San Marino"
    },
    "regionST": {
        "id": "region.ST",
        "defaultMessage": "São Tomé & Príncipe"
    },
    "regionSA": {
        "id": "region.SA",
        "defaultMessage": "Saudi Arabia"
    },
    "regionSN": {
        "id": "region.SN",
        "defaultMessage": "Senegal"
    },
    "regionRS": {
        "id": "region.RS",
        "defaultMessage": "Serbia"
    },
    "regionSC": {
        "id": "region.SC",
        "defaultMessage": "Seychelles"
    },
    "regionSL": {
        "id": "region.SL",
        "defaultMessage": "Sierra Leone"
    },
    "regionSG": {
        "id": "region.SG",
        "defaultMessage": "Singapore"
    },
    "regionSX": {
        "id": "region.SX",
        "defaultMessage": "Sint Maarten"
    },
    "regionSK": {
        "id": "region.SK",
        "defaultMessage": "Slovakia"
    },
    "regionSI": {
        "id": "region.SI",
        "defaultMessage": "Slovenia"
    },
    "regionSB": {
        "id": "region.SB",
        "defaultMessage": "Solomon Islands"
    },
    "regionSO": {
        "id": "region.SO",
        "defaultMessage": "Somalia"
    },
    "regionZA": {
        "id": "region.ZA",
        "defaultMessage": "South Africa"
    },
    "regionGS": {
        "id": "region.GS",
        "defaultMessage": "South Georgia & South Sandwich Islands"
    },
    "regionKR": {
        "id": "region.KR",
        "defaultMessage": "South Korea"
    },
    "regionSS": {
        "id": "region.SS",
        "defaultMessage": "South Sudan"
    },
    "regionES": {
        "id": "region.ES",
        "defaultMessage": "Spain"
    },
    "regionLK": {
        "id": "region.LK",
        "defaultMessage": "Sri Lanka"
    },
    "regionBL": {
        "id": "region.BL",
        "defaultMessage": "St. Barthélemy"
    },
    "regionSH": {
        "id": "region.SH",
        "defaultMessage": "St. Helena"
    },
    "regionKN": {
        "id": "region.KN",
        "defaultMessage": "St. Kitts & Nevis"
    },
    "regionLC": {
        "id": "region.LC",
        "defaultMessage": "St. Lucia"
    },
    "regionMF": {
        "id": "region.MF",
        "defaultMessage": "St. Martin"
    },
    "regionPM": {
        "id": "region.PM",
        "defaultMessage": "St. Pierre & Miquelon"
    },
    "regionVC": {
        "id": "region.VC",
        "defaultMessage": "St. Vincent & Grenadines"
    },
    "regionSD": {
        "id": "region.SD",
        "defaultMessage": "Sudan"
    },
    "regionSR": {
        "id": "region.SR",
        "defaultMessage": "Suriname"
    },
    "regionSJ": {
        "id": "region.SJ",
        "defaultMessage": "Svalbard & Jan Mayen"
    },
    "regionSE": {
        "id": "region.SE",
        "defaultMessage": "Sweden"
    },
    "regionCH": {
        "id": "region.CH",
        "defaultMessage": "Switzerland"
    },
    "regionSY": {
        "id": "region.SY",
        "defaultMessage": "Syria"
    },
    "regionTW": {
        "id": "region.TW",
        "defaultMessage": "Taiwan"
    },
    "regionTJ": {
        "id": "region.TJ",
        "defaultMessage": "Tajikistan"
    },
    "regionTZ": {
        "id": "region.TZ",
        "defaultMessage": "Tanzania"
    },
    "regionTH": {
        "id": "region.TH",
        "defaultMessage": "Thailand"
    },
    "regionTL": {
        "id": "region.TL",
        "defaultMessage": "Timor-Leste"
    },
    "regionTG": {
        "id": "region.TG",
        "defaultMessage": "Togo"
    },
    "regionTK": {
        "id": "region.TK",
        "defaultMessage": "Tokelau"
    },
    "regionTO": {
        "id": "region.TO",
        "defaultMessage": "Tonga"
    },
    "regionTT": {
        "id": "region.TT",
        "defaultMessage": "Trinidad & Tobago"
    },
    "regionTA": {
        "id": "region.TA",
        "defaultMessage": "Tristan da Cunha"
    },
    "regionTN": {
        "id": "region.TN",
        "defaultMessage": "Tunisia"
    },
    "regionTR": {
        "id": "region.TR",
        "defaultMessage": "Turkey"
    },
    "regionTM": {
        "id": "region.TM",
        "defaultMessage": "Turkmenistan"
    },
    "regionTC": {
        "id": "region.TC",
        "defaultMessage": "Turks & Caicos Islands"
    },
    "regionTV": {
        "id": "region.TV",
        "defaultMessage": "Tuvalu"
    },
    "regionUM": {
        "id": "region.UM",
        "defaultMessage": "U.S. Outlying Islands"
    },
    "regionVI": {
        "id": "region.VI",
        "defaultMessage": "U.S. Virgin Islands"
    },
    "regionUG": {
        "id": "region.UG",
        "defaultMessage": "Uganda"
    },
    "regionUA": {
        "id": "region.UA",
        "defaultMessage": "Ukraine"
    },
    "regionAE": {
        "id": "region.AE",
        "defaultMessage": "United Arab Emirates"
    },
    "regionGB": {
        "id": "region.GB",
        "defaultMessage": "United Kingdom"
    },
    "regionUS": {
        "id": "region.US",
        "defaultMessage": "United States"
    },
    "regionUY": {
        "id": "region.UY",
        "defaultMessage": "Uruguay"
    },
    "regionUZ": {
        "id": "region.UZ",
        "defaultMessage": "Uzbekistan"
    },
    "regionVU": {
        "id": "region.VU",
        "defaultMessage": "Vanuatu"
    },
    "regionVA": {
        "id": "region.VA",
        "defaultMessage": "Vatican City"
    },
    "regionVE": {
        "id": "region.VE",
        "defaultMessage": "Venezuela"
    },
    "regionVN": {
        "id": "region.VN",
        "defaultMessage": "Vietnam"
    },
    "regionWF": {
        "id": "region.WF",
        "defaultMessage": "Wallis & Futuna"
    },
    "regionEH": {
        "id": "region.EH",
        "defaultMessage": "Western Sahara"
    },
    "regionYE": {
        "id": "region.YE",
        "defaultMessage": "Yemen"
    },
    "regionZM": {
        "id": "region.ZM",
        "defaultMessage": "Zambia"
    },
    "regionZW": {
        "id": "region.ZW",
        "defaultMessage": "Zimbabwe"
    }
});

export default regionMessages;