import React from 'react';
import { ProductSearchState } from '../models/product-search-state';

import notfound from './../img/not-found.svg';
import './results.scss';
import './results-not-found.scss';
import { FormattedMessage } from 'react-intl';


export default class ResultsNotFound extends React.PureComponent<ProductSearchState> {
    render() {
        return (
            <div className="results-notfound">
                <div className="container">
                    <div className="row results-notfound--container">
                        <div className="col-auto">
                            <img src={notfound} alt="product not found" className="results-notfound--img"></img>
                        </div>
                        <div className="col">
                            <h3 className="results-notfound--caption__red">
                                <FormattedMessage id="not-found.header" defaultMessage="Your search did not match any products"></FormattedMessage>
                            </h3>
                            <div>
                                <FormattedMessage id="not-found.suggestions" defaultMessage="Suggestions:"></FormattedMessage>
                            </div>
                            <ul>
                                <li>
                                    <FormattedMessage id="not-found.suggestion.1" defaultMessage="Make sure that all words are spelled correctly"></FormattedMessage>
                                </li>
                                <li>
                                    <FormattedMessage id="not-found.suggestion.2" defaultMessage="Make sure your country is selected"></FormattedMessage>
                                </li>
                                <li>
                                    <FormattedMessage id="not-found.suggestion.3" defaultMessage="Try different keywords"></FormattedMessage>
                                </li>
                                <li>
                                    <FormattedMessage id="not-found.suggestion.4" defaultMessage="Try fewer keywords"></FormattedMessage>
                                </li>
                            </ul>
                            <button className="btn btn-outline-primary" onClick={this.props.onGoBack}>
                                <FormattedMessage id="not-found.button.back" defaultMessage="Back"></FormattedMessage>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}