import React from 'react';
import { ProductSearchState } from '../models/product-search-state';
import ResultsCompleted from './results-completed';
import ResultsStoreInfo from './results-store-info';
import ResultsProductVariants from './results-variants';
import Comments from './comments';

import './results.scss';

const req : any = require;
export const imgContext: (key: string) => string = req.context('./../img/store-logos');

export default class Results extends React.PureComponent<ProductSearchState> {
    render() {
        return (
            <div className="results">
                <ResultsCompleted {...this.props}></ResultsCompleted>
                <ResultsStoreInfo {...this.props}></ResultsStoreInfo>
                <ResultsProductVariants {...this.props}></ResultsProductVariants>
                <Comments {...this.props}></Comments>
            </div>
        );
    }
}