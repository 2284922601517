import React from 'react';
import TermsAndConditions from './footer-termsconditions';
import PrivacyPolicy from './footer-privacypolicy';

import './footer.scss';
import { FormattedMessage } from 'react-intl';
import { ProductSearchState } from '../models/product-search-state';

const Footer: React.FC<ProductSearchState> = (props) => {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col offset-md-1 col-md-10">
                        <div className="footer--copyright">
                            <FormattedMessage id="footer.all-rights-reserved" defaultMessage="© 2019 All Right Reserved."></FormattedMessage>
                        </div>
                        <div className="footer--links">
                            <PrivacyPolicy {...props} /> | <TermsAndConditions {...props} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;
