import React from 'react';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
import ReactScroll from 'react-scroll';
import * as URI from 'urijs';
import './App.scss';
import Footer from './components/footer';
import FooterProductSearch from './components/footer-product-search';
import Header from './components/header';
import Information from './components/information';
import ProductSearchLarge from './components/product-search-large';
import Results from './components/results';
import ResultsNotFound from './components/results-not-found';
import SearchOverlay from './components/search-overlay';
import { default as productMap } from './data/product-map.json';
import { default as regionData } from './data/region-data.json';
import regionMessages from './data/region-messages';
import { LoadProduct } from './loadProduct';
import de from './locales/de.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import da from './locales/da.json';
import fi from './locales/fi.json';
import it from './locales/it.json';
import ko from './locales/ko.json';
import nl from './locales/nl.json';
import no from './locales/no.json';
import pt from './locales/pt.json';
import sv from './locales/sv.json';
import ja from './locales/ja.json';
import lt from './locales/lt.json';
import bg from './locales/bg.json';
import cs from './locales/cs.json';
import el from './locales/el.json';
import et from './locales/et.json';
import hi from './locales/hi.json';
import hr from './locales/hr.json';
import hu from './locales/hu.json';
import id from './locales/id.json';
import lv from './locales/lv.json';
import ms from './locales/ms.json';
import pl from './locales/pl.json';
import ro from './locales/ro.json';
import ru from './locales/ru.json';
import sk from './locales/sk.json';
import sl from './locales/sl.json';
import th from './locales/th.json';
import vi from './locales/vi.json';
import zhs from './locales/zhs.json';
import zht from './locales/zht.json';
import ar from './locales/ar.json';
import he from './locales/he.json';
import { LocalizeProduct } from './localizeProduct';
import { ProductMap } from './models/product-map';
import { ProductSearchState } from './models/product-search-state';
import { ProductVariant } from './models/product-variant';
import { Region } from './models/region';



export const defaultLocale = 'en';
const messages: { [key: string]: any } = {
  'de': de,
  'es': es,
  'fr': fr,
  'da': da,
  'fi': fi,
  'it': it,
  'ko': ko,
  'nl': nl,
  'no': no,
  'pt': pt,
  'sv': sv,
  'ja': ja,
  'lt': lt,
  'bg': bg, 'cs': cs, 'el': el, 'et': et, 'hi': hi, 'hr': hr, 'hu': hu, 'id': id, 'lv': lv, 'ms': ms, 'pl': pl, 'ro': ro, 'ru': ru, 'sk': sk, 'sl': sl, 'th': th, 'vi': vi, 'zhs': zhs, 'zht': zht, 'ar': ar, 'he': he
};

class App extends React.Component<{}, ProductSearchState> {

  searchResultStack = [];

  constructor() {
    super({});

    this.onRegionSelected = this.onRegionSelected.bind(this);
    this.onProductSelected = this.onProductSelected.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onGoBack = this.onGoBack.bind(this);

    this.state = {
      products: productMap,
      regions: regionData,
      product: undefined,
      productPreselected: undefined,
      productForResult: undefined,
      region: undefined,
      regionForResult: undefined,
      onProductSelected: this.onProductSelected,
      onRegionSelected: this.onRegionSelected,
      onSearch: this.onSearch,
      onGoBack: this.onGoBack,
      searchedOnce: false,
      searchInProgress: false,
      noPriceAvailable: false,
      locale: 'en',
    }
  }

  localizeRegions(locale: string) {
    const cache = createIntlCache()

    const intl = createIntl({
      locale: locale,
      messages: messages[locale]
    }, cache);

    return regionData.map(region => {
      return {
        abbreviation: region.abbreviation,
        country: regionMessages['region' + region.abbreviation] ? intl.formatMessage(regionMessages['region' + region.abbreviation]) : region.country,
        campid: region?.campid,
      };
    }).sort((a, b) => a.country.localeCompare(b.country));
  }

  onProductSelected(product?: ProductMap) {
    this.setState({ product: product });
  }

  onRegionSelected(region?: Region) {
    this.setState({ region: region });
  }

  onSearch() {
    const that = this;
    this.setState({
      searchInProgress: true,
    });
    ReactScroll.animateScroll.scrollToTop();

    if (this.state.region && this.state.product) {

      LoadProduct(this.state.product.slug, this.state.locale).then(response => {
        //Check if price is available
        let priceAvailable = false;
        response[0].variants.forEach((variant: ProductVariant) => {
          const match = variant.pricing.find(f => f.regionCode === this.state.region!.abbreviation);
          if (match) {
            priceAvailable = true;
          }
        })

        if (priceAvailable) {
          // Add localized messages
          messages[that.state.locale] = { ...messages[that.state.locale], ...response[1], ...response[2], ...response[3], ...response[4] };

          // Set state to trigger page render
          that.setState({
            productForResult: LocalizeProduct(response[0]),
            regionForResult: this.state.region,
            searchInProgress: false,
            searchedOnce: true,
            noPriceAvailable: false,
          });
        } else {
          that.setState({
            searchInProgress: false,
            searchedOnce: true,
            regionForResult: undefined,
            productForResult: undefined,
            noPriceAvailable: false,
          });
        }
      });
    } else {
      setTimeout(() => {
        that.setState({
          searchInProgress: false,
          searchedOnce: true,
          regionForResult: undefined,
          productForResult: undefined,
          noPriceAvailable: false,
        });
      }, 1200);
    }
  }

  onGoBack() {
    this.setState({
      searchedOnce: false,
    });
  }

  getQueryParams() {
    const urlQueryMap: any = URI.parseQuery(URI.parse(window.location.href).query || '');
    let regions = [];
    const lng = localStorage.getItem('lng');
    if (lng && Object.keys(messages).find(l => l === lng)) {
      regions = this.localizeRegions(lng);
      this.setState({
        locale: lng,
        regions: regions
      });
    } else {
      let locale = urlQueryMap['lang'];
      locale = Object.keys(messages).find(l => l === locale) || 'en';
      regions = this.localizeRegions(locale);
      this.setState({
        locale: locale,
        regions: regions
      });
      localStorage.setItem('lng', locale);
    }

    const campid: string = urlQueryMap['campid'];
    const campidNumber = parseInt(campid);

    if (!isNaN(campidNumber)) {
      const providedRegion = regions.find(f => {
        if (Array.isArray(f.campid) && f.campid.find(ff => ff === campidNumber)) {
          return true;
        } else {
          return false;
        }
      });
      if (providedRegion) {
        this.setState({
          region: providedRegion
        });
      }
    }

    const prod = urlQueryMap['k'];

    if (prod) {
      this.state.products.forEach(product => {
        const match = product.keywords.find(f => f.toLowerCase() === prod.toLowerCase());
        if (match) {
          this.setState({
            product: product,
            productPreselected: product,
          });
        }
      });
    }


  }

  componentDidMount() {
    this.getQueryParams();
  }

  displayResults() {
    if (this.state.searchedOnce) {
      if (this.state.productForResult && this.state.regionForResult) {
        if (this.state.noPriceAvailable) {
          // May show a more specific error
          return (
            <ResultsNotFound {...this.state}></ResultsNotFound>
          );
        } else {
          return (
            <Results {...this.state}></Results>
          );
        }
      } else {
        return (
          <ResultsNotFound {...this.state}></ResultsNotFound>
        );
      }
    } else {
      return (
        <>
          <Information {...this.state} />
          <ProductSearchLarge {...this.state} />
          <FooterProductSearch  {...this.state} />
        </>
      )
    }
  }

  render() {
    if (this.state.locale === 'ar' || this.state.locale === 'he') {
    return (
      <body dir="rtl" style={{textAlign: "right"}}>
      <IntlProvider key={this.state.locale} locale={this.state.locale} messages={messages[this.state.locale]}>
        <React.StrictMode />
        <Header  {...this.state}></Header>
        {this.displayResults()}
        <Footer {...this.state}></Footer>
        <SearchOverlay {...this.state}></SearchOverlay>
      </IntlProvider>
      </body>
    );
} else {
  return (
    <IntlProvider key={this.state.locale} locale={this.state.locale} messages={messages[this.state.locale]}>
      <React.StrictMode />
      <Header  {...this.state}></Header>
      {this.displayResults()}
      <Footer {...this.state}></Footer>
      <SearchOverlay {...this.state}></SearchOverlay>
    </IntlProvider>
  );
}
  }
}

export default App;
