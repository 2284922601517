import React from 'react';
import { ProductSearchState } from '../models/product-search-state';

import './search-overlay.scss';


export default class SearchOverlay extends React.PureComponent<ProductSearchState> {
    render() {
        const { searchInProgress } = this.props;
        return (
            <div className={searchInProgress ? 'search-overlay search-overlay-fade-in' : 'search-overlay'}>
                <div className="search-overlay-loader">
                </div>
            </div>
        );
    }
}
