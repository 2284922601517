import React from 'react';
import ProductSearch from './product-search';
import { ProductSearchState } from '../models/product-search-state';

import './header-product-search.scss';



export default class HeaderProductSearch extends React.PureComponent<ProductSearchState> {
    getSearchRow() {
        if(!this.props.searchedOnce){
            return (
                <div className="header-product-search">
                <div className="container">
                    <div className="row">
                        <div className="col offset-lg-1 col-lg-9">
                            <ProductSearch {...this.props}></ProductSearch>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }

    render() {
        return (
            <div>{this.getSearchRow()}</div>
            
        );
    }
}
