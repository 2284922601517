import ModalDialog from './modal';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function CommentThankyou() {
    return (
        <ModalDialog linkName={<FormattedMessage id="button.submit" defaultMessage="SUBMIT"></FormattedMessage>} caption={<FormattedMessage id="button.submit.caption" defaultMessage="SUCCESS!"></FormattedMessage>}>
            <div className="comments--modal-contents">
                <h4>
                    <FormattedMessage id="modal.thank-you.caption" defaultMessage="THANK YOU FOR YOUR COMMENT"></FormattedMessage>
                </h4>
                <p>
                    <FormattedMessage id="modal.thank-you.message" defaultMessage="Your comment is being reviewed by our moderator."></FormattedMessage>
                </p>
            </div>
        </ModalDialog>
    )
}
