import React, { ChangeEvent, useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FormattedMessage, useIntl } from 'react-intl';
import '../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
import { ProductSearchState } from '../models/product-search-state';
import { Region } from '../models/region';
import messages from './messages';
import './product-search-large.scss';
import verified from './../img/check.svg';



const ProductSearchLarge: React.FC<ProductSearchState> = (props) => {

    const intl = useIntl();

    const [productName, setProductName] = useState('');
    const [productMatch, setProductMatch] = useState(false);

    useEffect(() => {
        if (props.product && !productMatch) {
            setProductName(props.product.name);
        }
    });

    const onProductSearched = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            const searchToken = e.target.value.toLowerCase().trim();
            const product = props.products.find(f => {
                return f.name === searchToken || f.keywords.find(ff => ff.toLowerCase() === searchToken);
            });
            if (product) {
                props.onProductSelected(product);
                setProductMatch(true);
            } else {
                props.onProductSelected(undefined);
                setProductMatch(false);
            }
        } else {
            props.onProductSelected(undefined);
            setProductMatch(false);
        }
        setProductName(e.target.value);
    }

    const onRegionSelected = (region: Region[]) => {
        if (region && region.length) {
            props.onRegionSelected(region[0]);
        } else {
            props.onRegionSelected(undefined);
        }
    }
    const showVerified = () => {
        return productMatch ? (<img className="product-search--verified" src={verified}></img>) : '';
    }

    return (
        <div className="container">
            <div className="col offset-lg-1 col-lg-10">
                <div className="product-search-large--container">
                    <div className="product-search-large--caption">
                        <FormattedMessage id="product-search-large.start-your-search" defaultMessage="Start Your Search"></FormattedMessage>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                        <input 
                        name="product-search" 
                        className="rbt-input-main form-control rbt-input  input-lg form-control-lg" 
                        placeholder={intl.formatMessage(messages.enterProductName)} 
                        onChange={onProductSearched} 
                        value={productName}>
                    </input>
                    {showVerified()}
                        </div>
                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                            <Typeahead
                                id='region-search-large'
                                inputProps={{ name: 'region-search', autoComplete: 'off' }}
                                bsSize={'large'}
                                labelKey={r => r.country}
                                maxResults={50}
                                multiple={false}
                                clearButton={true}
                                selected={props.region ? [props.region] : []}
                                options={props.regions}
                                placeholder={intl.formatMessage(messages.enterCountryName)}
                                onChange={onRegionSelected}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <button className="btn btn-primary btn-lg product-search-large--button" onClick={props.onSearch}>
                            {intl.formatMessage(messages.btnSearch)}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ProductSearchLarge;
