import React, { useState } from 'react';
import StarRatings from './star-rating';
import { ProductSearchState } from '../models/product-search-state';
import { Product } from '../models/product';
import { Comment } from '../models/comment';
import CommentThankyou from './comment-thankyou';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import messages from './messages';

import './comments.scss';
import userIcon from './../img/user.jpg';
import {userNames} from '../data/userNames';



function getCommentDate(index: number) {
    var d = new Date();
    var dateOffset = [1, 3, 3, 5, 6, 7, 8, 10, 10, 15, 16, 17, 18, 19, 20, 21, 23, 25, 26];
    var diff = d.getDate() - d.getDay() + (d.getDay() === 0 ? -6 : 1) - dateOffset[index];
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var lang = (window.navigator && window.navigator.language) || 'en-US';
    d.setDate(diff);
    return <FormattedDate value={d} year="numeric" month="long" day="numeric"></FormattedDate>;
}

function getCommentUserName(index: number, locale: string, commentName: string) {
    var userNames1 = userNames as any; 
    
    var commenterName;

    if (locale === 'en') {
        if (commentName === 'man') {
        commenterName = userNames.us1[index];
        } else if (commentName === 'woman') {
            commenterName = userNames.us2[index];
        } else {
            commenterName = userNames.us[index];
        }
    } else {
        if (commentName === 'man') {
            locale=locale+'1';
                commenterName = userNames1[locale][index];
            } else if (commentName === 'woman') {
                locale=locale+'2';
                commenterName = userNames1[locale][index];
            } else {
                commenterName = userNames1[locale][index];
            }
    }
            
    return commenterName;
}

function renderComments(comments: Comment[], locale: string) {

    return comments.map((comment, i) => {
        return (
            <div key={i} className="row comments__record">
                <div className="col-12 col-md-2 col-xl-1 text-md-center ">
                    <img src={userIcon} className="img-fluid mb-2 mb-md-0" alt="avatar"></img>
                </div>
                <div className="col-12 col-md-10 col-xl-11">
                    <div className="row">
                        <div className="col-12 col-md">
                            <div><strong> {getCommentUserName(i, locale, comment.name)}</strong></div>
                            <div className="comments__date mb-md-2">{getCommentDate(i)}</div>
                        </div>
                        <div className="col-12 col-md-auto mb-2 mb-md-0">
                            <StarRatings
                                rating={comment.rating}
                                starDimension="1.6em"
                                starSpacing="0.1em"
                                starRatedColor="#faca12"
                                starEmptyColor="#c2c2c2"
                                starHoverColor="#faca12"
                                numberOfStars={5}
                                name='rating'
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {comment.message}
                        </div>
                    </div>
                </div>
            </div>
        );
    })
}

const Comments: React.FC<ProductSearchState> = (props) => {
    const [userRating, setUserRating] = useState<number>(5);
    const intl = useIntl();

    const product = props.productForResult as Product;
    if (!product) {
        return (<></>);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col comments">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="comments__header">
                                <FormattedMessage id="comments.header" defaultMessage="Visitors Comments"></FormattedMessage>
                            </h2>
                        </div>
                        <div className="col-12">
                            {renderComments(product.comments, props.locale)}
                        </div>
                        <div className="col-12 pb-5">
                            <h2 className="comments__header">
                                <FormattedMessage id="comments.customer-reviews" defaultMessage="Customers Reviews"></FormattedMessage>
                            </h2>
                            <form>
                                <div className="form-group row">
                                    <label htmlFor="name" className="col-sm-2 col-form-label">
                                        <FormattedMessage id="comments.name" defaultMessage="Name:"></FormattedMessage>
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="name" placeholder={intl.formatMessage(messages.enterYourName)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="review" className="col-sm-2 col-form-label">
                                        <FormattedMessage id="comments.review" defaultMessage="Review:"></FormattedMessage>
                                    </label>
                                    <div className="col-sm-10">
                                        <textarea className="form-control" id="review" rows={4} placeholder={intl.formatMessage(messages.tellUs)}></textarea>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="rating" className="col-sm-2 col-form-label">
                                        <FormattedMessage id="comments.rating" defaultMessage="Rating:"></FormattedMessage>
                                    </label>
                                    <div className="col-12 col-md">
                                        <StarRatings
                                            id="rating"
                                            rating={userRating}
                                            starDimension="1.75rem"
                                            starSpacing="0.125rem"
                                            starRatedColor="#faca12"
                                            starEmptyColor="#c2c2c2"
                                            starHoverColor="#faca12"
                                            numberOfStars={5}
                                            isSelectable={true}
                                            changeRating={setUserRating}
                                            onHoverCallback={() => { }}
                                            onBlurCallback={() => { }}
                                            name='userRating'
                                        />
                                    </div>
                                    <div className="col-12 col-md-auto text-center mt-4 mt-md-0">
                                        <button type="button" className="btn btn-primary comments__btn"><CommentThankyou /></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Comments;
