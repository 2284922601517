import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import lora from './../img/lora.jpg';

import './information.scss';
import { ProductSearchState } from '../models/product-search-state';


export default class Information extends React.PureComponent<ProductSearchState> {

    getTitle() {
        if (this.props.productPreselected && this.props.productPreselected.name) {
            return (
                <h1>
                    <FormattedMessage 
                        id="information.parametrized-title" 
                        defaultMessage="Struggling to find the best deal for {product} while online shopping? It’s now possible in two simple steps." 
                        values={{
                            product: this.props.productPreselected.name
                        }}
                    />
                </h1>
            )
        } else {
            return (
                <h1>
                    <FormattedMessage 
                        id="information.default-title" 
                        defaultMessage="Struggling to find the best deal for your product? It’s now possible in two simple steps." 
                    />
                </h1>
            )
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col information">
                        {this.getTitle()}
                        <div className="mt-5 mb-2"><strong><FormattedMessage id="information.reviewer-name" defaultMessage="Lora Deli"></FormattedMessage></strong> | <FormattedDate value={new Date()} year="numeric" month="long" day="numeric"></FormattedDate></div>
                        <img className="img-fluid mb-5" src={lora} alt="Lora Dely" />
                        <p><FormattedMessage id="information.section1.p1" defaultMessage="Nowadays, more and more people prefer online shopping over visiting a regular store. It comes with many different advantages and benefits that physical stores are not always able to offer. Convenience plays the biggest role here – you can shop anytime, anywhere, access the stores located in different continents, there are no crowds or irritating salespeople pressuring you to buy their products no matter what… But not a lot of people know that online stores usually offer much better prices and allow you to take advantage of some great multi-buy bargains since you are able to buy directly from the manufacturer without involving anyone else into the process." values={{ strong: (...chunks: any) => (<strong>{chunks}</strong>) }}></FormattedMessage></p>
                        <h2>
                            <FormattedMessage id="information.section2.header.1.1" defaultMessage="Why is it important to shop at official stores?"></FormattedMessage>
                        </h2>
                        <h5>
                            <FormattedMessage id="information.section2.header.1.2" defaultMessage="Buying from an official store ensures you get the best possible deal – both price and quality-wise."></FormattedMessage>
                        </h5>
                        <p>
                            <FormattedMessage id="information.section2.p2" defaultMessage="If you buy from anywhere else rather than the official store, you’ll notice that the price is usually significantly higher. Moreover, official stores provide trustful reviews, various extra discounts, premium customer service, secure warranty, a possibility to return, etc. They are committed to their brand more than anyone else could ever be, resulting in efficiency, promptness, and excellent shopping experience overall." values={{ strong: (...chunks: any) => (<strong>{chunks}</strong>) }}></FormattedMessage>
                        </p>
                        <p>
                            <FormattedMessage id="information.section2.p3" defaultMessage="Meanwhile, ordering products from third-party websites and various unauthorized resellers may pose many challenges. The chances you might become the victim of fake ecommerce sites increase, and there is always a possibility you may receive products that are counterfeit, defective, used, or you might not even receive them at all! Not to mention the unreasonably higher prices…" values={{ strong: (...chunks: any) => (<strong>{chunks}</strong>) }}></FormattedMessage>
                        </p>
                        <p>
                            <FormattedMessage id="information.section2.p4" defaultMessage="What is more, various intermediaries are focused on only one thing – the sale itself. They don’t really care about the rest. Official websites create experience – they put a lot of time and effort into making it pleasant for you from “the first step” you take in their online store. It starts with user friendly design and ends with direct, personal communication that isn’t available at any other intermediary website… <strong>So how to make sure you’re buying from an official store and getting the best possible deal?</strong> If you’re not sure whether or not you’re using an official platform to purchase something you desire, there is now a fast solution to find it out and avoid the unpleasant consequences – and it’s called <strong>Official Store Finder</strong>." values={{ strong: (...chunks: any) => (<strong>{chunks}</strong>) }}></FormattedMessage>
                        </p>

                        <h2>
                            <FormattedMessage id="information.section3.header" defaultMessage="How does it work?"></FormattedMessage>
                        </h2>
                        <p>
                            <FormattedMessage id="information.section3.p1" defaultMessage="Official Store Finder is a powerful tool that lists all the official websites that sell the product you’re looking for. Just by filling two fields – product name and country - you’re able to access product’s official store instantly, without putting any effort into surfing the endless internet yourself." values={{ strong: (...chunks: any) => (<strong>{chunks}</strong>) }}></FormattedMessage>
                        </p>
                        <p>
                            <FormattedMessage id="information.section3.p2" defaultMessage="Since every result you get while running a search on the platform is carefully checked by its editors and verified by the community feedback, you can be sure that Official Store Finder helps you identify the most trustful places to shop online that offer the best prices and other shopping conditions." values={{ strong: (...chunks: any) => (<strong>{chunks}</strong>) }}></FormattedMessage>
                        </p>

                        <h2>
                            <FormattedMessage id="information.section4.header" defaultMessage="Give it a try in only two steps"></FormattedMessage>
                        </h2>
                        <ol>
                            <li>
                                <FormattedMessage id="information.section4.step1" defaultMessage="Type the name of a product;"></FormattedMessage>
                            </li>
                            <li>
                                <FormattedMessage id="information.section4.step2" defaultMessage="Choose your country from the list. That’s it, happy and, most importantly, safe shopping!"></FormattedMessage>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}