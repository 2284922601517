import React from 'react';
import ProductSearch from './product-search';
import { ProductSearchState } from '../models/product-search-state';

import './footer-product-search.scss';


export default class FooterProductSearch extends React.PureComponent<ProductSearchState> {
    render() {
        return (
            <div className="footer-product-search">
                <div className="container">
                    <div className="row">
                        <div className="col offset-lg-1 col-lg-10">
                            <ProductSearch {...this.props}></ProductSearch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}