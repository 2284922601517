import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProductSearchState } from '../models/product-search-state';
import { Product } from '../models/product';
import { Region } from '../models/region';

import './results-completed.scss';
import finder from './../img/finder.png';

export default class ResultsCompleted extends React.PureComponent<ProductSearchState> {

    render() {
        const product = this.props.productForResult as Product;
        const region = this.props.regionForResult as Region;
        if (!product) {
            return
        }
        return (
            <div className="container">
                <div className="results-completed">
                    <div className="row">
                        <div className="col-12 col-md-auto">
                            <img src={finder} alt="finder" className="results-completed--finder-img"></img>
                        </div>
                        <div className="col">
                            <h1 className="results-completed--caption">
                                <FormattedMessage
                                    id="results-completed.header"
                                    defaultMessage="<b>Search Completed</b> for {product}"
                                    values={{
                                        b: (...chunks: any[]) => (<span className="results-completed--caption__primary">{chunks}</span>),
                                        product: product.name
                                    }} />
                            </h1>
                            <p>
                                <FormattedMessage
                                    id="results-completed.thank-you-text"
                                    defaultMessage="Thank you for using OfficialStoreFinder! Based on your search request, we located the official online store that allows you to purchase {productName} in {country}. Please see the information below to find the official store information, latest prices, best available deals, and customer reviews to help you make an informative decision."
                                    values={{
                                        productName: product.name,
                                        country: region.country
                                    }} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}