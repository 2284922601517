import React from 'react';
import Modal from 'react-bootstrap/Modal';

type ModalState = {
    show: boolean;
}

type ModalProps = {
    linkName: any,
    caption?: any
}

export default class ModalDialog extends React.PureComponent<ModalProps, ModalState> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
            show: false
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    handleClose = () => this.setState({ show: false });
    handleShow = (event: any) => {this.setState({ show: true }); event.preventDefault(); return false;};

    render() {
        const show = this.state.show;
        return (
            <>
                <a href="#" onClick={this.handleShow}>{this.props.linkName}</a>
                <Modal show={show} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <strong>{this.props.caption || this.props.linkName}</strong>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.children}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}