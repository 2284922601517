import { Product, ProductStoreHref } from './models/product';
import  URITemplate from 'urijs/src/URITemplate';
import {default as URI} from 'urijs';
import { defaultLocale } from './App';

const populateUrl = (templateUrl: string) => {
    const template = new URITemplate(templateUrl).parse();
    const currentUrl = new URI(window.location.href);
    const url = template.expand(URI.parseQuery(currentUrl.query()) as any);
    return url.valueOf();
}

const processUrl = (url: any) => {
    if (url.hasOwnProperty(defaultLocale)) {
        const urlMap = url as ProductStoreHref;
        for (const key of Object.keys(urlMap)) {
            urlMap[key] = populateUrl(urlMap[key])
        }
        return urlMap;
    } else {
        return populateUrl(url as string);
    }
}

export function LoadProduct(slug: string, locale: string) {
    const productUrl = `${process.env.PUBLIC_URL}/data/products/${slug}.json`;
    const commentsUrl = `${process.env.PUBLIC_URL}/data/product.comments.${locale}.json`;
    const commentsDefaultUrl = `${process.env.PUBLIC_URL}/data/product.comments.en.json`;
    const commonDescriptionsUrl = `${process.env.PUBLIC_URL}/data/product.common.descriptions.${locale}.json`;
    const commonDescriptionsDefaultUrl = `${process.env.PUBLIC_URL}/data/product.common.descriptions.en.json`;
    const descriptionsUrl = `${process.env.PUBLIC_URL}/data/product.descriptions.${locale}.json`;
    const descriptionsDefaultUrl = `${process.env.PUBLIC_URL}/data/product.descriptions.en.json`;
    const variantsUrl = `${process.env.PUBLIC_URL}/data/product.variants.${locale}.json`;
    const variantsDefaultUrl = `${process.env.PUBLIC_URL}/data/product.variants.en.json`;

    const p1 = fetch(productUrl).then(response => response.text())
        .then(text => {
            if (text.indexOf('<!DOCTYPE html>') === 0) {
                return Promise.reject('not found');
            }
            const product = JSON.parse(text) as Product;
            
            product.href = processUrl(product.href);
            product.variants.forEach(variant => {
                variant.href = processUrl(variant.href);
            });

            return product;
        });

    const p2 = fetch(commentsUrl)
        .then(response => response.text())
        .then(text => {
            if (text.indexOf('<!DOCTYPE html>') === 0) {
                return fetch(commentsDefaultUrl).then(response => response.json())

            } else {
                return JSON.parse(text);
            }
        });

    const p3 = fetch(commonDescriptionsUrl)
        .then(response => response.text())
        .then(text => {
            if (text.indexOf('<!DOCTYPE html>') === 0) {
                return fetch(commonDescriptionsDefaultUrl).then(response => response.json())

            } else {
                return JSON.parse(text);
            }
        });

    const p4 = fetch(descriptionsUrl)
        .then(response => response.text())
        .then(text => {
            if (text.indexOf('<!DOCTYPE html>') === 0) {
                return fetch(descriptionsDefaultUrl).then(response => response.json())

            } else {
                return JSON.parse(text);
            }
        });

    const p5 = fetch(variantsUrl)
        .then(response => response.text())
        .then(text => {
            if (text.indexOf('<!DOCTYPE html>') === 0) {
                return fetch(variantsDefaultUrl).then(response => response.json())

            } else {
                return JSON.parse(text);
            }
        });

    return Promise.all([p1, p2, p3, p4, p5]);
}
